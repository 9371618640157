<template lang="html">
  <div>
    <Header :animated="false" />
    <section id="faq" class="feymargins animated heartBeat">
        <div class="container">

            <div class="section-header">
                <h3 class="section-title">{{ $trs('faq.title') }}</h3>
                <span class="section-divider"></span>
                <p class="section-description">Sed ut perspiciati de omnis iste natus error sit voluptatem accusantium doloremque</p>
            </div>

            <div class="accordion accordion-flush" id="accordion" >

              <div class="accordion-item">
                <h2 class="accordion-header" id="faq01">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse01" aria-expanded="false" aria-controls="collapse01">
                    {{ $trs('faq.question-01') }}
                  </button>
                </h2>
                <div id="collapse01" class="accordion-collapse collapse" aria-labelledby="faq01" data-bs-parent="#accordion">
                  <div class="accordion-body">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vitae quam fringilla mauris sagittis
                    dignissim. Praesent lacinia ante leo, et faucibus nulla feugiat quis. Pellentesque habitant morbi
                    tristique senectus et netus et malesuada fames ac turpis egestas. Proin et tempor ex, et varius odio.
                    Sed quis accumsan leo, vitae suscipit felis. Maecenas gravida placerat turpis ac fermentum. Curabitur
                    condimentum odio nec mi ornare egestas dignissim tempus ex. Vestibulum varius nunc sed consectetur
                    imperdiet. Cras sed posuere purus. Nulla at turpis nunc. Nunc nec leo nibh. Praesent at libero molestie,
                    mollis nisl gravida, mattis lectus. Nulla sit amet lorem non tortor bibendum dignissim eu sed ligula.
                    Ut eu volutpat elit.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="faq02">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse02" aria-expanded="false" aria-controls="collapse02">
                    {{ $trs('faq.question-02') }}
                  </button>
                </h2>
                <div id="collapse02" class="accordion-collapse collapse" aria-labelledby="faq02" data-bs-parent="#accordion">
                  <div class="accordion-body">
                    Etiam vitae dictum lorem, quis bibendum tellus. Mauris ac augue vitae felis dapibus laoreet. Phasellus
                    lacinia sapien justo, non tempor libero dapibus a. Proin rutrum eu elit quis elementum. Donec vitae nisi
                    eget eros blandit sagittis non at justo. In vehicula nibh dolor, eget convallis turpis auctor non.
                    Vivamus semper ipsum sapien, et scelerisque risus maximus cursus. Maecenas laoreet elementum ultricies.
                    Sed sed dapibus ante. Nam pharetra, ipsum id dapibus ullamcorper, tortor nisi auctor tellus, quis ullamcorper
                    orci arcu non tellus. Donec magna nibh, rhoncus non turpis vitae, ultricies maximus est.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="faq03">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse03" aria-expanded="false" aria-controls="collapse03">
                    {{ $trs('faq.question-03') }}
                  </button>
                </h2>
                <div id="collapse03" class="accordion-collapse collapse" aria-labelledby="faq03" data-bs-parent="#accordion">
                  <div class="accordion-body">
                    Aliquam maximus ipsum et nisi finibus molestie quis ac orci. Sed fermentum, est eget scelerisque volutpat,
                    magna nisl hendrerit justo, ut lobortis ex mi eu ipsum. Integer egestas, nisl non lacinia porttitor, neque
                    metus faucibus ligula, ac fermentum mauris dolor id felis. Nullam bibendum justo eget ex scelerisque, ac
                    congue arcu varius. Sed facilisis libero et libero tristique, eu finibus velit posuere. Aenean vulputate
                    est quis turpis laoreet aliquet. Praesent blandit est velit. Nulla malesuada dolor nec consequat dapibus.
                    Sed fringilla massa quis venenatis tincidunt.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="faq04">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse04" aria-expanded="false" aria-controls="collapse04">
                    {{ $trs('faq.question-04') }}
                  </button>
                </h2>
                <div id="collapse04" class="accordion-collapse collapse" aria-labelledby="faq04" data-bs-parent="#accordion">
                  <div class="accordion-body">
                    Praesent eget justo aliquet, mollis nisi ac, dapibus nibh. Integer quis accumsan velit. Donec ultricies dolor
                    ut est ullamcorper, sit amet dictum eros malesuada. In et dolor in risus condimentum dapibus. Sed hendrerit
                    metus eget dolor gravida porttitor. Vivamus tincidunt volutpat nunc, rutrum finibus purus maximus quis. Nulla
                    et egestas risus, et pretium ipsum.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="faq05">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse05" aria-expanded="false" aria-controls="collapse05">
                    {{ $trs('faq.question-05') }}
                  </button>
                </h2>
                <div id="collapse05" class="accordion-collapse collapse" aria-labelledby="faq05" data-bs-parent="#accordion">
                  <div class="accordion-body">
                    Sed scelerisque leo eget ullamcorper vulputate. Aliquam eu elementum mauris. Donec faucibus elementum laoreet.
                    Aenean at ligula molestie, faucibus odio vitae, pulvinar ipsum. Aliquam lacinia vulputate iaculis. Sed at dolor
                    ac leo elementum viverra condimentum et ligula. Etiam a luctus arcu.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="cookie">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse06" aria-expanded="false" aria-controls="collapse06">
                    {{ $trs('faq.question-06') }}
                  </button>
                </h2>
                <div id="collapse06" class="accordion-collapse collapse" aria-labelledby="cookie" data-bs-parent="#accordion">
                  <div class="accordion-body">
                    Lorsque vous utilisez notre site, nous pouvons être amenés à enregistrer des cookies sur votre poste. Ces
                    informations nous permettant de reconnaître le navigateur que vous utilisez, de nous souvenir de vos préférences,
                    et d'assurer la sécurité de votre connexion à notre site.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="faq07">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse07" aria-expanded="false" aria-controls="collapse07">
                    {{ $trs('faq.question-07') }}
                  </button>
                </h2>
                <div id="collapse07" class="accordion-collapse collapse" aria-labelledby="faq07" data-bs-parent="#accordion">
                  <div class="accordion-body">
                    Sed sed dapibus ante. Nam pharetra, ipsum id dapibus ullamcorper, tortor nisi auctor tellus, quis
                    ullamcorper orci arcu non tellus. Donec magna nibh, rhoncus non turpis vitae, ultricies maximus est.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="faq08">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse08" aria-expanded="false" aria-controls="collapse08">
                    {{ $trs('faq.question-08') }}
                  </button>
                </h2>
                <div id="collapse08" class="accordion-collapse collapse" aria-labelledby="faq08" data-bs-parent="#accordion">
                  <div class="accordion-body">
                    Nullam bibendum justo eget ex scelerisque, ac congue arcu varius. Sed facilisis libero et libero tristique,
                    eu finibus velit posuere. Aenean vulputate est quis turpis laoreet aliquet. Praesent blandit est velit.
                    Nulla malesuada dolor nec consequat dapibus. Sed fringilla massa quis venenatis tincidunt.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="faq09">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse09" aria-expanded="false" aria-controls="collapse09">
                    {{ $trs('faq.question-09') }}
                  </button>
                </h2>
                <div id="collapse09" class="accordion-collapse collapse" aria-labelledby="faq09" data-bs-parent="#accordion">
                  <div class="accordion-body">
                    Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire. Il trouve ses
                    racines dans une oeuvre de la littérature latine classique datant de 45 av. J.-C., le rendant vieux de 2000
                    ans. Un professeur du Hampden-Sydney College, en Virginie, s'est intéressé à un des mots latins les plus obscurs,
                    consectetur, extrait d'un passage du Lorem Ipsum, et en étudiant tous les usages de ce mot dans la littérature
                    classique, découvrit la source incontestable du Lorem Ipsum. Il provient en fait des sections 1.10.32 et 1.10.33
                    du "De Finibus Bonorum et Malorum" (Des Suprêmes Biens et des Suprêmes Maux) de Cicéron. Cet ouvrage, très
                    populaire pendant la Renaissance, est un traité sur la théorie de l'éthique. Les premières lignes du Lorem Ipsum,
                    "Lorem ipsum dolor sit amet...", proviennent de la section 1.10.32.
                  </div>
                </div>
              </div>

            </div>

        </div>
    </section>
    <Footer/>
  </div>
</template>



<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Faq',
  components: {
    Header,
    Footer
  },
}
</script>




<style lang="css" scoped>

.accordion-button {
    color: #0078ff;
    font-weight: 700;
}

.collapsed {
    color: #0078ff;
    font-weight: 400;
}

</style>
